export const locations = [
  {
    id: '1',
    name: 'Los Angeles',
    parent_id: '0',
    route: 'los-angeles',
    state: 'CA',
    zipCode: [],
    area: [
      {
        id: '7',
        name: 'USC',
        parent_id: '1',
        route: 'usc',
        zipCode: [90007, 90018]
      },
      {
        id: '8',
        name: 'Koreatown',
        parent_id: '1',
        route: 'koreatown',
        zipCode: [90010, 90004, 90005, 90006]
      },
      {
        id: '9',
        name: 'West LA',
        parent_id: '1',
        route: 'west-la',
        zipCode: [90025, 90034, 90064, 90024]
      },
      {
        id: '18',
        name: 'Hollywood',
        parent_id: '1',
        route: 'hollywood',
        zipCode: [90004, 90029, 90028]
      },
      {
        id: '43',
        name: 'DTLA',
        parent_id: '1',
        route: 'downtown-la',
        zipCode: [90015]
      },
      {
        id: '19',
        name: 'Palms',
        parent_id: '1',
        route: 'palms',
        zipCode: []
      },
      {
        id: '20',
        name: 'Century City',
        parent_id: '1',
        route: 'century-city',
        zipCode: []
      }
    ]
  },
  {
    id: '22',
    name: 'West Covina',
    parent_id: '0',
    route: 'west-covina',
    state: 'CA',
    zipCode: [],
    area: [
      {
        id: '221',
        name: 'Walnut Valley',
        parent_id: '22',
        route: 'walnut-valley',
        zipCode: [91791]
      }
    ]
  },
  {
    id: '23',
    name: 'Glendale',
    parent_id: '0',
    route: 'glendale',
    state: 'CA',
    zipCode: [91205],
    area: []
  },
  {
    id: '24',
    name: 'West Hollywood',
    parent_id: '0',
    route: 'west-hollywood',
    state: 'CA',
    zipCode: [90048],
    area: []
  },
  {
    id: '3',
    name: 'Seattle',
    parent_id: '0',
    route: 'seattle',
    state: 'WA',
    zipCode: [],
    area: [
      {
        id: '11',
        name: 'University District',
        parent_id: '3',
        route: 'university-district',
        zipCode: [98103, 98115, 98144, 98105]
      },
      {
        id: '40',
        name: 'Downtown Seattle',
        parent_id: '3',
        route: 'downtown-seattle',
        zipCode: [98122, 98109]
      },
      {
        id: '41',
        name: 'West Seattle',
        parent_id: '3',
        route: 'west-seattle',
        zipCode: [98126]
      },
      {
        id: '42',
        name: 'Ballard',
        parent_id: '3',
        route: 'ballard',
        zipCode: [98107]
      },
      {
        id: '45',
        name: 'Fremont',
        parent_id: '3',
        route: 'fremont',
        zipCode: [98103]
      },
      {
        id: '53',
        name: 'Roosevelt',
        parent_id: '3',
        route: 'roosevelt',
        zipCode: [98115]
      },
      {
        id: '54',
        name: 'Westlake',
        parent_id: '3',
        route: 'westlake',
        zipCode: [98109]
      }
    ]
  },
  {
    id: '52',
    name: 'Bellevue',
    parent_id: '0',
    route: 'bellevue',
    state: 'WA',
    zipCode: [98004],
    area: []
  },
  {
    id: '4',
    name: 'Philadelphia',
    route: 'philadelphia',
    parent_id: '0',
    state: 'PA',
    zipCode: [],
    area: [
      {
        id: '12',
        name: 'University City',
        parent_id: '4',
        route: 'university-city',
        zipCode: [19104]
      },
      {
        id: '21',
        name: 'Temple University',
        parent_id: '4',
        route: 'temple-university',
        zipCode: [19121]
      }
    ]
  },
  {
    id: '2',
    name: 'Pittsburgh',
    route: 'pittsburgh',
    parent_id: '0',
    state: 'PA',
    zipCode: [15203, 15237, 15232],
    area: [
      {
        id: '14',
        name: 'Squirrel Hill',
        parent_id: '2',
        route: 'squirrel-hill',
        zipCode: [15213, 15217]
      },
      {
        id: '15',
        name: 'Oakland',
        parent_id: '2',
        route: 'oakland',
        zipCode: [15219, 15213]
      }
    ]
  },
  {
    id: '5',
    name: 'Irvine',
    route: 'irvine',
    parent_id: '0',
    state: 'CA',
    zipCode: [],
    area: [
      {
        id: '17',
        name: 'UCI Area',
        parent_id: '5',
        route: 'uci-area',
        zipCode: [92614]
      }
    ]
  },
  {
    id: '6',
    name: 'Tucson',
    route: 'tucson',
    parent_id: '0',
    state: 'AZ',
    zipCode: [],
    area: [
      {
        id: '13',
        name: 'UA',
        parent_id: '6',
        route: 'ua',
        zipCode: [85719]
      }
    ]
  },
  {
    id: '44',
    name: 'Tacoma',
    parent_id: '0',
    state: 'WA',
    route: 'tacoma',
    zipCode: [98402],
    area: []
  },
  {
    id: '46',
    name: 'Chicago',
    parent_id: '0',
    route: 'chicago',
    state: 'IL',
    zipCode: [60608],
    area: [
      {
        id: '47',
        name: 'Lincoln Park',
        parent_id: '46',
        route: 'lincoln-park',
        zipCode: []
      },
      {
        id: '48',
        name: 'Pilsen',
        parent_id: '46',
        route: 'pilsen',
        zipCode: []
      },
      {
        id: '49',
        name: 'UIC',
        parent_id: '46',
        route: 'uic',
        zipCode: []
      }
    ]
  },
  {
    id: '50',
    name: 'Berkeley',
    parent_id: '0',
    route: 'berkeley',
    state: 'CA',
    zipCode: [],
    area: [
      {
        id: '51',
        name: 'Downtown Berkeley',
        parent_id: '50',
        route: 'downtown-berkeley',
        zipCode: []
      }
    ]
  },
  {
    id: '55',
    name: 'Irving',
    parent_id: '0',
    route: 'irving',
    state: 'TX',
    zipCode: [75039],
    area: []
  },
  {
    id: '60',
    name: 'Houston',
    parent_id: '0',
    route: 'houston',
    state: 'TX',
    zipCode: [77042, 77030, 77477, 77072, 77077, 77082, 77090],
    area: []
  },
  {
    id: '80',
    name: 'Killeen',
    parent_id: '0',
    route: 'killeen',
    state: 'TX',
    zipCode: [76543],
    area: []
  },
  {
    id: '90',
    name: 'Corpus Christi',
    route: 'corpus-christi',
    state: 'TX',
    parent_id: '0',
    zipCode: [78414, 78413],
    area: []
  },
  {
    id: '10',
    name: 'Baltimore',
    parent_id: '0',
    route: 'baltimore',
    state: 'MD',
    zipCode: [],
    area: [
      {
        id: '101',
        name: 'Remington',
        parent_id: '10',
        route: 'remington',
        zipCode: [21211]
      }
    ]
  }
]

export const locationsLatLng = [
  {
    name: 'Los Angeles',
    state: '',
    center: { lat: 34.0522, lng: -118.2437 },
    area: [
      {
        name: 'USC',
        center: { lat: 34.021511, lng: -118.286604 }
      },
      {
        name: 'Koreatown',
        center: { lat: 34.057768308466, lng: -118.2942159914 }
      },
      {
        name: 'West LA',
        center: { lat: 34.032102, lng: -118.422128 }
      },
      {
        name: 'Hollywood',
        center: { lat: 34.087369560703, lng: -118.30862791536 }
      },
      {
        name: 'DTLA',
        center: { lat: 34.041686, lng: -118.2662567 }
      },
      {
        name: 'Palms',
        center: { lat: 34.024891, lng: -118.407502 }
      },
      {
        name: 'Century City',
        center: { lat: 34.0589365, lng: -118.4164023 }
      }
    ]
  },
  {
    name: 'Glendale',
    state: '',
    center: { lat: 34.14564, lng: -118.255859 },
    area: []
  },
  {
    name: 'West Hollywood',
    center: { lat: 34.089929, lng: -118.375838 },
    area: []
  },
  {
    name: 'West Covina',
    state: '',
    center: { lat: 34.064748, lng: -117.929444 },
    area: [
      {
        name: 'Walnut Valley',
        center: { lat: 34.064748, lng: -117.929444 }
      }
    ]
  },
  {
    name: 'Seattle',
    state: '',
    center: { lat: 47.6062, lng: -122.3321 },
    area: [
      {
        name: 'University District',
        center: { lat: 47.667938255405, lng: -122.31382538673 }
      },
      {
        name: 'Downtown Seattle',
        center: { lat: 47.618316854281, lng: -122.32708431768 }
      },
      {
        name: 'West Seattle',
        center: { lat: 47.565890180829, lng: -122.37711911768 }
      },
      {
        name: 'Ballard',
        center: { lat: 47.673454465618, lng: -122.37666181582 }
      },
      {
        name: 'Fremont',
        center: { lat: 47.653457829505, lng: -122.3548577598 }
      },
      {
        name: 'Roosevelt',
        center: { lat: 47.676634, lng: -122.317567 }
      },
      {
        name: 'Westlake',
        center: { lat: 47.633837, lng: -122.341133 }
      }
    ]
  },
  {
    name: 'Bellevue',
    state: 'WA',
    center: { lat: 47.616399, lng: -122.191271 }
  },
  {
    name: 'Philadelphia',
    state: '',
    center: { lat: 39.962782, lng: -75.196713 },
    area: [
      {
        name: 'University City',
        center: { lat: 39.962782, lng: -75.196713 }
      },
      {
        name: 'Temple University',
        center: { lat: 39.977061274668, lng: -75.159744759983 }
      }
    ]
  },
  {
    name: 'Pittsburgh',
    state: '',
    center: { lat: 40.4406, lng: -79.9959 },
    area: [
      {
        name: 'Squirrel Hill',
        center: { lat: 40.4268864, lng: -79.9289213 }
      },
      {
        name: 'Oakland',
        center: { lat: 40.42671300000001, lng: -79.93709129999999 }
      }
    ]
  },
  {
    name: 'Irvine',
    state: '',
    center: { lat: 33.6846, lng: -117.8265 },
    area: [
      {
        name: 'UCI Area',
        center: { lat: 33.6424, lng: -117.8417 }
      }
    ]
  },
  {
    name: 'Tucson',
    state: '',
    center: { lat: 32.254, lng: -110.9742 },
    area: [
      {
        name: 'UA',
        center: { lat: 32.2398147, lng: -110.951858 }
      }
    ]
  },
  {
    name: 'Tacoma',
    state: '',
    center: { lat: 47.2529, lng: -122.4443 },
    area: []
  },
  {
    name: 'Chicago',
    state: '',
    center: { lat: 41.8781, lng: -87.6298 },
    area: [
      {
        name: 'Lincoln Park',
        center: { lat: 41.8570307, lng: -87.6561274 }
      },
      {
        name: 'Pilsen',
        center: { lat: 41.8570307, lng: -87.6561274 }
      },
      {
        name: 'UIC',
        center: { lat: 41.8612685, lng: -87.6620432 }
      }
    ]
  },
  {
    name: 'Berkeley',
    state: '',
    center: { lat: 37.8715, lng: -122.273 },
    area: [
      {
        name: 'Downtown Berkeley',
        center: { lat: 37.8721788, lng: -122.2765762 }
      }
    ]
  },
  {
    name: 'Houston',
    state: '',
    center: { lat: 29.762781, lng: -95.365456 }
  },
  {
    name: 'Irving',
    state: '',
    center: { lat: 32.887891, lng: -96.964026 }
  },
  {
    name: 'Killeen',
    state: '',
    center: { lat: 31.115485, lng: -97.727029 }
  },
  {
    name: 'Corpus Christi',
    state: '',
    center: { lat: 27.802325, lng: -97.394055 }
  },
  {
    name: 'Baltimore',
    state: '',
    center: { lat: 39.310862, lng: -76.615181 },
    area: [
      {
        name: 'Remington',
        center: { lat: 39.320127, lng: -76.62403 }
      }
    ]
  }
]

export const propertyTypes = [
  {
    id: 'any',

    name: 'Any',
    msg: 'All space types',
    type: 'ALL'
  },
  {
    id: 'sharedHome',

    name: 'Shared Home',
    msg: 'Live with your friends',
    type: 'COLIVING'
  }
] as const

// the relation in path cityName 关联关系
const getCityAreaMap = () =>
  locations.reduce(
    (p, c) => {
      p[c.route] = c.name
      if (c.area) {
        c.area.forEach(v => {
          p[v.route] = v.name
        })
      }
      return p
    },
    { '': '' }
  )

// 区域价格区间用户限制所在区域价格内敛过滤掉不合适的价格
export const locationPriceRange = {
  'Los Angeles': [1001, 99999999],
  Irving: [100, 99999999],
  'Corpus Christi': [100, 99999999],
  Houston: [100, 99999999],
  Killeen: [100, 99999999]
}

/**
 * 通过locations获取城市区域对应的location显示文本
 * @returns {Object}
 */
const getCityAreaLocationNameMap = () =>
  locations.reduce((p, c) => {
    p[c.name] = `${c.name}, ${c.state}`
    if (c.area) {
      c.area.forEach(v => {
        p[v.name] = `${v.name}, ${c.name}, ${c.state}`
      })
    }
    return p
  }, {})

export const cityAreaLocationNameMap = getCityAreaLocationNameMap()

export const cityRouteMap = getCityAreaMap()

export const cityMapCN = {
  'Los Angeles': '洛杉矶',
  Seattle: '西雅图',
  Pittsburgh: '匹兹堡',
  Philadelphia: '费城',
  Irvine: '尔湾',
  Tucson: '图森',
  Chicago: '芝加哥',
  berkeley: '伯克利'
} as const

export const propertyMap = {
  default: 'Any',
  COLIVING: 'Shared Home',
  APARTMENTHUNTINGTIPS: 'Full Apartment'
} as const
