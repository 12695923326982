import { HOME_SEARCH_SEND_TYPE } from '@/components/newHome/homeSearch/input'

export const setAppSource = (value: string) => {
  window.sessionStorage.removeItem('app_source')
  window.sessionStorage.setItem('app_source', value)
}

export const getAppSource = () => {
  const value = window.sessionStorage.getItem('app_source')
  return value || ''
}

export const setSuggestionInStorage = (data: HOME_SEARCH_SEND_TYPE) => {
  window.sessionStorage.removeItem('new_home_suggestion')
  window.sessionStorage.setItem('new_home_suggestion', JSON.stringify(data))
}

export const getSuggestionInStorage = () => {
  const value = window.sessionStorage.getItem('new_home_suggestion')
  if (value) {
    return JSON.parse(value) as HOME_SEARCH_SEND_TYPE
  }
  return null
}
