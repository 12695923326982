// 当前正在聊天的property
import { makeVar } from '@apollo/client'
import { RentyAIProperty } from '@/interface/property'
import { MAP_FACILITY_ENUM } from '@/interface/map'

export const chatActiveProperty = makeVar<RentyAIProperty & { detailLoading?: boolean }>({})

// for chat selected property
export const mapActiveProperty = makeVar<RentyAIProperty>({})

// 当前用户选择的property周边设置type
export const currentMapFacility = makeVar<MAP_FACILITY_ENUM | null>(null)

export const chatActivePropertyList = makeVar<RentyAIProperty[]>([])

// 用户进入详情的路由list
export const lastRoutePathList = makeVar([])

// 上次点击房源卡片进入详情的property
export const lastSelectProperty = makeVar<RentyAIProperty>({})
