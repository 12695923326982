import invert from 'lodash/invert'
import trim from 'lodash/trim'
import { cityRouteMap, cityMapCN, locations } from '../asset/searchData'
import { findCaFromZipCode } from './zipCode'

const getCityFromCNName = city => {
  const citys = invert(cityMapCN)
  return citys[city] || undefined
}

export const getCaOrZipData = (caOrZip = '', pageTitle = '') => {
  const caData = {
    city: '',
    area: '',
    cityLowcaseName: '',
    areaLowcaseName: ''
  }

  if (caOrZip.includes('zip')) {
    return {
      type: 'zip',
      value: findCaFromZipCode(pageTitle.replace(/[^0-9]/gi, ''))
    }
  }

  locations.find(({ area, name: cityName, route: cityPath }) => {
    if (trim(cityPath.toLowerCase()) === trim(caOrZip.toLowerCase())) {
      caData.city = cityName
      caData.cityLowcaseName = cityPath
      return true
    }
    const matchArea = (area || []).find(({ name: areaName, route: areaPath }) => {
      if (trim(areaPath.toLowerCase()) === trim(caOrZip.toLowerCase())) {
        caData.city = cityName
        caData.cityLowcaseName = cityPath
        caData.area = areaName
        caData.areaLowcaseName = areaPath
        return true
      }
      return false
    })
    return matchArea
  })
  return {
    type: 'ca',
    value: caData
  }
}

const getCityAreaValue = (city, area) => {
  const cityNameFromCNName = getCityFromCNName(city)
  const cityName = cityNameFromCNName || city

  const citys = invert(cityRouteMap)

  const getValue = name => {
    const value = Object.keys(citys).find(
      key => key?.toLowerCase().replace(/\s+/g, '') === name.toLowerCase().replace(/\s+/g, '')
    )
    if (value && citys[value]) {
      return {
        name: value,
        path: citys[value] || ''
      }
    }
    return {
      name: '',
      path: ''
    }
  }
  const cityMap = cityName ? getValue(cityName) : { name: '', path: '' }
  const areaMap = area ? getValue(area) : { name: '', path: '' }
  return {
    city: cityMap,
    area: areaMap
  }
}

const getCityAreaValueFromPath = (city, area) => {
  const citys = cityRouteMap

  const getValue = name => {
    const value = Object.keys(citys).find(
      key => key?.toLowerCase().replace(/\s+/g, '') === name.toLowerCase().replace(/\s+/g, '')
    )
    if (value && citys[value]) {
      return {
        name: citys[value] || '',
        path: value
      }
    }
    return {
      name: '',
      path: ''
    }
  }
  const cityMap = city ? getValue(city) : {}
  const areaMap = area ? getValue(area) : {}
  return {
    city: cityMap,
    area: areaMap
  }
}

const getPropertyType = type => {
  switch (type) {
    case 'COLIVING':
      return true
    case 'APARTMENTHUNTINGTIPS':
      return false
    default:
      return 'default'
  }
}

// get the city area data from the url query
const getCityAreaNamesFromQuery = query => {
  const { cityAreaRoute, city, area, list } = query || {}
  if (cityAreaRoute) {
    const cityAreaSplit =
      (cityAreaRoute && cityAreaRoute[0] && decodeURIComponent(cityAreaRoute[0]).split('/')) || []
    const isHasCityArea = cityAreaRoute && cityAreaRoute.length && cityAreaRoute.length === 2
    const oldPathParams = isHasCityArea
      ? [decodeURIComponent(cityAreaRoute[0]), decodeURIComponent(cityAreaRoute[1])]
      : [cityAreaSplit[0] || '', cityAreaSplit[1] || '']

    const { city: cityData, area: areaData } =
      city || area
        ? getCityAreaValue(
            city ? decodeURIComponent(city) : '',
            area ? decodeURIComponent(area) : ''
          )
        : getCityAreaValueFromPath(...oldPathParams)
    return { city: cityData, area: areaData }
  }
  // 新路由
  if (list) {
    const [cityType, cityValue, areaValue] = list || []
    if (cityType === 'city') {
      return getCityAreaValue(decodeURIComponent(cityValue), decodeURIComponent(areaValue))
    }
    return {}
  }
  return {}
}

export { getCityAreaValue, getPropertyType, getCityAreaValueFromPath, getCityAreaNamesFromQuery }
