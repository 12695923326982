// the Breakpoints same with the tailwind config
export const themeConfig = {
  typography: {
    fontFamily: 'inherit'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 0
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1179, // for the ipad rotate width: ipad 旋转屏幕
      xl: 1536
    }
  },
  palette: {
    black: {
      main: '#050A22',
      dark: '#000',
      100: '#F5F5FA',
      200: '#121620'
    },
    white: {
      main: '#FFFFFF'
    },
    gray: {
      main: '#DDDDDD',
      100: '#F7F7F7',
      200: '#E9E9E9',
      300: '#DDDDDD'
    },
    tertiary: {
      main: '#B0B0B0'
    },
    primary: {
      main: '#001229'
    },
    secondary: {
      main: '#717171'
    },
    brand: {
      main: '#004D34'
    }
  }
}
