import { cityRouteMap, locations } from '@/asset/searchData'
import { invert } from 'lodash'
import kebabCase from 'lodash/kebabCase'
import { getCityAreaValue, getCaOrZipData } from '@/lib/cityArea'
import { lastRoutePathList } from '@/state/chatActiveProperty'

/**
 * 根据房源信息获取房源详情页面的url
 * @param city
 * @param area
 * @param type
 * @param name
 * @param id 房源id
 * @param unitId unitId
 * @returns url
 */
export const getDetailPageUrlPath = (
  city = 'city',
  _ = '',
  name = 'name',
  id: string | number = 0,
  unitId = 0
) => {
  const cityAreaMap = invert(cityRouteMap)
  const cityItem = locations.find(v => v.name === city)
  return `/apartment-buildings/${cityAreaMap[city] || kebabCase(city)}-${kebabCase(
    cityItem?.state || 'state'
  )}/${kebabCase(name)}/${id}${unitId ? `/${unitId}` : ''}`
}

/**
 * 根据详情url获取房源信息
 * @param url
 * @returns {city, area, type, name, id} ｜ null
 */
export const parseDetailPageUrlPath = (url: string) => {
  const regex = /^\/apartment-buildings\/([\w-]+)\/([\w-]+)\/([\w-]+)(\/([\w-]+))?/
  const match = url.match(regex)

  if (match) {
    const [, city, name, id, , unitId] = match
    return {
      city,
      name,
      id,
      unitId
    }
  }

  return null
}

/**
 * @description 传入cityName,areaName，获取对应的路由
 * @param - cityName
 * @param - areaName
 */
export const generatePath = (cityName?: string, areaName?: string) => {
  if (!cityName && !areaName) {
    return '/apartments-for-rent'
  }
  if (kebabCase(areaName).startsWith('usc')) {
    const uscPath = 'usc'
    const { value } = getCaOrZipData(uscPath)
    const city = locations.find(v => v.name === value.city)
    return `/off-campus-housing/${city.route}-${kebabCase(city.state)}/${uscPath}`
  }
  const { city, area } = getCityAreaValue(cityName, areaName)
  const cityItem = locations.find(v => v.name === cityName)
  return `/apartments-for-rent/${city.path}-${kebabCase(cityItem.state) || 'state'}${
    area.path ? '/' : ''
  }${area.path}`
}

export const generateZipcodePath = (zipCode: string) => {
  if (!zipCode) {
    return ''
  }
  return `/zip/${zipCode}-apartment`
}

/**
 * @description 静默更新url path，不刷新页面
 * @param property - RentyAIproperty? - 不传的话，就是回退，否则就改变url path
 */
export const goDetailWithoutFlush = (property = null) => {
  if (!property || !property.id) {
    return
  }
  const { city, area, name, id } = property
  const list = lastRoutePathList()
  const path = getDetailPageUrlPath(city, area, name, id)
  lastRoutePathList(list.concat([path]))
  window.history.pushState(
    {
      as: path,
      url: path,
      options: { shallow: true }
    },
    '',
    path
  )
}

/**
 * @description 详情页回退，在详情页调用
 */
export const backFromDetail = () => {
  if (window.history.state.url?.startsWith?.('/apartment-buildings/')) {
    const list = lastRoutePathList()
    if (list.length) {
      lastRoutePathList([])
      let i = list.length
      while (i > 0) {
        window.history.back()
        i -= 1
      }
    } else {
      window.location.href = generatePath()
    }
  }
}
