import { useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { parseCookies } from 'nookies'
// import { concatPagination } from '@apollo/client/utilities'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { parse } from 'cookie'
import { omitDeep } from '@/lib/omitDeep'
import { getIp } from './getIp'
import { searchCriteria, localLeaseList, localFilters } from './catch'
import { chatSessionId } from '../state/chatId'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
const isProd = process.env.NODE_ENV === 'production'
let apolloClient

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URI, // Server URL (must be absolute)
  credentials: 'same-origin'
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ extensions: { errorType, response } = {} }) => {
      if (['PERMISSION_DENIED'].includes(errorType)) {
        // window.location.href = '/login'
        console.error(errorType)
        // new Notification('GraphQL Error', { body: `${errorType}: ${message}` })
      }
      // Login invalid case
      const { status = 0 } = response || {}
      if (status === 401) {
        // notification.info({
        //   message: `Login invalid, please login again `,
        //   top: 20
        // })
        // setTimeout(() => {
        // window.location.href = '/login'
        // }, 1000)
      }
    })
  }

  if (networkError) {
    console.error('networkError', JSON.stringify(networkError))
    // new Notification('Network Error', { body: (networkError || '').toString() })
  }
})

const createApolloClient = req => {
  const clientCookies = parseCookies()
  const { headers } = req || {}
  const { cookie } = headers || {}
  const serverCookies = parse(cookie || '')
  const { loginUserToken: serverToekn = null } = serverCookies || {}
  const { loginUserToken: clientToken = null } = clientCookies || {}
  const accessToken = serverToekn || clientToken || ''

  // 活动价格临时方案，全局拦截
  const priceActivityAfterWare = new ApolloLink((operation, forward) =>
    forward(operation).map(response => {
      // 处理响应逻辑
      // 可以访问 response.data 和 response.errors
      const { data } = response
      const operations = [
        'queryDialogHistoryList',
        'rentyAIUnitSearch',
        'queryRentyAIProperties',
        'rentyAIChat',
        'rentInfo',
        'rentyAIFavoriteList',
        'queryRentyAIProperty',
        'queryRentyAIGuessYouLikeHouse',
        'rentyRecommendUserProperties',
        'switchDialog'
      ]

      const extraConcesssions = {
        amount: 30,
        concessionType: 'Renty.AI Exclusive Discount',
        formattedCondition: ''
      }
      const addConcessions = (concessions, amount) =>
        concessions
          ? concessions.concat({ ...extraConcesssions, amount })
          : [{ ...extraConcesssions, amount }]

      const operationName = operations.find(v => !!data[v])
      const updatePrice = (units, rentyDiscount = null) =>
        units?.map(p => {
          if (p?.rentyDiscount) {
            p.minPrice = Math.max(0, p.minPrice - p.rentyDiscount).toFixed(1)
            if (p.rentyWhole !== 1) {
              p.maxPrice = Math.max(0, p.maxPrice - p.rentyDiscount).toFixed(1)
            }
            p.concessionsDates = addConcessions(p.concessionsDates, p.rentyDiscount)
          } else if (rentyDiscount) {
            p.price -= rentyDiscount
          }
          if (p?.rooms) {
            p.rooms = updatePrice(p.rooms, p.rentyDiscount)
          }
          return p
        })
      if (operationName) {
        if (
          [
            'rentyAIFavoriteList',
            'queryRentyAIGuessYouLikeHouse',
            'queryRentyAIProperties'
          ].includes(operationName) &&
          data[operationName]
        ) {
          const { data: list } = data[operationName]
          data[operationName] = {
            ...data[operationName],
            data: (list || []).map(v => {
              if (v.rentyDiscount) {
                v.minPrice = Math.max(0, v.minPrice - v.rentyDiscount).toFixed(1)
                v.concessionsDates = addConcessions(v.concessionsDates, v.rentyDiscount)
              }
              return v
            })
          }
        }

        if (
          ['queryDialogHistoryList', 'switchDialog', 'rentyAIUnitSearch', 'rentyAIChat'].includes(
            operationName
          ) &&
          data[operationName]
        ) {
          const { dialogHistoryMessage, messages } = data[operationName]
          const setExtraData = list =>
            list
              ? list.map(v => {
                  const properties = v.extraData?.searchProperties?.data
                  const property = v.extraData?.propertyDetail
                  const unitRecommendation = v.extraData?.unitRecommendation

                  if (properties) {
                    v.extraData.searchProperties.data = properties?.map(p => {
                      if (p.rentyDiscount) {
                        p.minPrice = Math.max(0, p.minPrice - p.rentyDiscount).toFixed(1)
                        p.concessionsDates = addConcessions(p.concessionsDates, p.rentyDiscount)
                      }
                      return p
                    })
                  }
                  if (property && property.rentyDiscount) {
                    v.extraData.propertyDetail = {
                      ...property,
                      minPrice: Math.max(0, property.minPrice - property.rentyDiscount).toFixed(1),
                      concessionsDates: addConcessions(
                        property.concessionsDates,
                        property.rentyDiscount
                      ),
                      floorPlanModeUnit: Array.isArray(property.floorPlanModeUnit)
                        ? property.floorPlanModeUnit
                        : [],
                      matchedFloorPlanModeUnit: Array.isArray(property.matchedFloorPlanModeUnit)
                        ? property.matchedFloorPlanModeUnit
                        : []
                    }
                  }
                  if (unitRecommendation) {
                    v.extraData.unitRecommendation = {
                      ...unitRecommendation,
                      matchingUnits: updatePrice(unitRecommendation.matchingUnits),
                      otherUnits: updatePrice(unitRecommendation.otherUnits)
                    }
                  }
                  return v
                })
              : list
          if (dialogHistoryMessage) {
            data[operationName] = {
              ...data[operationName],
              dialogHistoryMessage: setExtraData(dialogHistoryMessage)
            }
          }
          if (messages) {
            data[operationName] = {
              ...data[operationName],
              messages: setExtraData(messages)
            }
          }
        }
        const updateUnitPrice = units =>
          units?.map(unit => {
            if (unit?.rentyDiscount) {
              unit.minPrice = Math.max(0, unit.minPrice - unit.rentyDiscount).toFixed(1)
              if (unit.rentyWhole !== 1) {
                unit.maxPrice = Math.max(0, unit.maxPrice - unit.rentyDiscount).toFixed(1)
              }
              unit.concessionsDates = addConcessions(unit.concessionsDates, unit.rentyDiscount)
            }
            if (unit?.rooms) {
              unit.rooms = updateRoomsPrice(unit.rooms)
            }
            return unit
          })
        const updateRoomsPrice = rooms =>
          rooms?.map(room => {
            if (room?.rentyDiscount) {
              room.price = Math.max(0, room.price - room.rentyDiscount).toFixed(1)
              room.concessionsDates = addConcessions(room.concessionsDates, room.rentyDiscount)
            }
            return room
          })
        if (operationName === 'queryRentyAIProperty' && data[operationName]?.rentyDiscount) {
          const {
            minPrice,
            concessionsDates,
            rentyDiscount,
            availableUnits,
            floorPlanModeUnit,
            matchedFloorPlanModeUnit
          } = data[operationName]
          data[operationName] = {
            ...data[operationName],
            minPrice: Math.max(0, minPrice - rentyDiscount).toFixed(1),
            concessionsDates: addConcessions(concessionsDates, rentyDiscount),
            availableUnits: updateUnitPrice(availableUnits),
            floorPlanModeUnit: (floorPlanModeUnit || []).map(floorPlan => {
              const availableRentyDiscountUnits = updateUnitPrice(floorPlan?.availableUnits) ?? []
              const floorPlanMinPrice = Math.max(
                0,
                Math.min(
                  ...availableRentyDiscountUnits
                    .filter(u => u.availableStatus !== 2)
                    .map(u => u.minPrice)
                )
              ).toFixed(1)
              return {
                ...floorPlan,
                // TODO floorPlanMinPricFoolPlacvxue也需要减rentyDiscount，但现在缺少这个字段
                floorPlanMinPrice,
                availableUnits: availableRentyDiscountUnits
              }
            }),
            matchedFloorPlanModeUnit: (matchedFloorPlanModeUnit || []).map(floorPlan => {
              const availableRentyDiscountMatchedUnits =
                updateUnitPrice(floorPlan?.availableUnits) ?? []
              const floorPlanMinPrice = Math.max(
                0,
                Math.min(
                  ...availableRentyDiscountMatchedUnits
                    .filter(u => u.availableStatus !== 2)
                    .map(u => u.minPrice)
                )
              ).toFixed(1)
              return {
                ...floorPlan,
                floorPlanMinPrice,
                availableUnits: availableRentyDiscountMatchedUnits
              }
            })
          }
        }

        if (operationName === 'rentInfo' && data[operationName]?.baseRentList) {
          const { baseRentList, rentyDiscount } = data[operationName]
          if (rentyDiscount) {
            data[operationName] = {
              ...data[operationName],
              baseRentList: baseRentList?.map(v => {
                v.baseRents = v.baseRents?.map(p => {
                  const newExtra = {
                    ...extraConcesssions,
                    amount: rentyDiscount
                  }
                  const newConcessions = p.concessions
                    ? p.concessions.concat([newExtra])
                    : [newExtra]
                  return {
                    ...p,
                    concessions: newConcessions
                  }
                })
                return v
              })
            }
          }
        }
        if (operationName === 'rentyRecommendUserProperties') {
          const { recommendList } = data[operationName]
          data[operationName] = {
            ...data[operationName],
            recommendList: {
              data: (recommendList.data || []).map(v => {
                if (v.rentyDiscount) {
                  v.minPrice = Math.max(0, v.minPrice - v.rentyDiscount).toFixed(1)
                  v.concessionsDates = addConcessions(v.concessionsDates, v.rentyDiscount)
                }
                return v
              })
            }
          }
        }
      }

      // 返回响应给下一个链接或组件
      return { ...response, data }
    })
  )

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, '__typename')
    }
    const token = process.env.TOKEN
    let clintIpInfo = {}
    if (req) {
      clintIpInfo = {
        'X-Forwarded-For': getIp(req)
      }
    }
    operation.setContext(({ headers: headerData = {} }) => ({
      headers: {
        ...headerData,
        ...clintIpInfo,
        Authorization: `Bearer ${token || ''}`,
        Credential: accessToken,
        Channel: 'renty'
      }
    }))

    return forward(operation)
  })
  const link = from([priceActivityAfterWare, authMiddleware, errorLink, httpLink])

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    connectToDevTools: !isProd,
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            searchCriteria: { read: () => searchCriteria() },
            localLeaseList: { read: () => localLeaseList() },
            localFilters: { read: () => localFilters() },
            chatSessionId: { read: () => chatSessionId() }
          }
        }
      }
    })
  })
}

const initializeApollo = (req = null, initialState = null) => {
  const apolloClientTmp = apolloClient ?? createApolloClient(req)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClientTmp.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    })

    // Restore the cache with the merged data
    apolloClientTmp.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClientTmp
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = apolloClientTmp

  return apolloClientTmp
}

const addApolloState = (client, pageProps) => {
  if (pageProps?.props) {
    const { props } = pageProps
    props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

const useApollo = pageProps => {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(null, state), [state])
  return store
}

export { initializeApollo, addApolloState, useApollo }
