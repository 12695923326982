import { makeVar } from '@apollo/client'

export const localVariableString = makeVar('')
export const localVariableArray = makeVar([])
export const localVariableObject = makeVar({})
export const localVariableNumber = makeVar(0)

// search
export const searchCriteria = makeVar({
  location: {
    name: '',
    route: {
      levelOne: '',
      levelTwo: ''
    }
  },
  property: {
    name: '',
    type: ''
  }
})

export const searchSwitch = makeVar(false)
export const loginDialogOpen = makeVar(false)
export const isShowLoginTap = makeVar(true)

// list page data
export const localLeaseList = makeVar({
  leaseData: null,
  pageInfo: null,
  mapData: null
})
// list page hover propertety id
export const localListHoverId = makeVar('')

// p2 地图移动时经纬度找房用到的state，其他情况不要写进来
export const localMapIdle = makeVar({
  idleMode: false,
  location: null,
  city: '',
  area: '',
  cityLowcaseName: '',
  areaLowcaseName: ''
})

// list page filters
export const localFilters = makeVar({
  pageTitle: '',
  city: '',
  area: '',
  cityLowcaseName: '',
  areaLowcaseName: '',
  type: '',
  propertyType: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  bedsFilterBy: undefined,
  pageNum: 1,
  pageSize: 10,
  isPrivateBathroom: undefined,
  isParking: undefined,
  isInUnitLaundry: undefined,
  isAC: undefined,
  isPets: undefined,
  isFurnished: undefined,
  moveInStartTimeEqualTo: undefined,
  moveInEndTimeEqualTo: undefined,
  isColiving: undefined,
  marketingTag: undefined,
  oneBed: undefined,
  orderBy: null,
  isDps: undefined,
  isShuttleBus: undefined
})

// default filter values when init the list page
export const localFiltersDefault = makeVar({
  pageTitle: '',
  city: '',
  area: '',
  cityLowcaseName: '',
  areaLowcaseName: '',
  type: '',
  propertyType: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  bedsFilterBy: undefined,
  pageNum: 1,
  pageSize: 10,
  isPrivateBathroom: undefined,
  isParking: undefined,
  isInUnitLaundry: undefined,
  isAC: undefined,
  isPets: undefined,
  isFurnished: undefined,
  moveInStartTimeEqualTo: undefined,
  moveInEndTimeEqualTo: undefined,
  isColiving: undefined,
  marketingTag: undefined,
  oneBed: undefined,
  orderBy: null,
  isDps: undefined,
  isShuttleBus: undefined
})

// Blog
export const localBlogList = makeVar({
  blogData: [],
  pageInfo: null
})

// Recommend
export const recommendFilters = makeVar({
  city: '',
  area: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  bedsFilterBy: undefined,
  moveInStartTimeEqualTo: undefined,
  step: undefined // TODO  next or back
})

// login user info
export const userInfoCatch = makeVar({
  email: '',
  name: '',
  userId: '',
  firstName: '',
  lastName: ''
})

// share model config
export const shareConfig = makeVar({
  open: false,
  unitId: '',
  roomId: '',
  type: '',
  classify: '',
  otherUrl: null,
  statsig: null,
  selectName: '',
  isShareActivity: false,
  rewards: 0
})

// 缓存一次session里favorites操作
export const currentSessionFavorites = makeVar(new Map())
