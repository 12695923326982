import base64 from 'base-64'

const getUserInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem('loginUserInfo')) || {}
  return userInfo
}

const deleteCookie = (cookieName = 'loginUserToken') => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

const getShareUser = shareObj =>
  shareObj ? JSON.parse(decodeURIComponent(base64.decode(shareObj))) : {}

const automaticLogon = () => {
  localStorage.setItem('automaticLogon', 'automaticLogon')
}

export { getUserInfo, deleteCookie, getShareUser, automaticLogon }
