import { Box, Backdrop } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { useReactiveVar } from '@apollo/client'
import { popularShow } from '@/state/popular'
import LinkList from './linkList'

const NearSearches = ({ isMobileDevice = false }) => {
  const open = useReactiveVar(popularShow)

  const handleCloseSearch = () => {
    popularShow(false)
  }

  return (
    <>
      {/* 底部弹窗，因为Drawer只是js渲染， 不能在服务端ssr，所以自己写了 */}
      <Backdrop className="z-[1300]" open={open} onClick={handleCloseSearch} />
      <Box
        component="div"
        className="fixed bg-white bottom-0 left-0 right-0 rounded-t-xl md:rounded-t-2xl overflow-hidden transition-transform h-[60vh] lg:h-auto"
        sx={{
          zIndex: 1302,
          transform: open ? 'translateY(0)' : 'translateY(100%)'
        }}
      >
        {isMobileDevice ? (
          <>
            <Box component="div" className="text-base font-bold text-center pt-2 relative">
              Nearby searches
              <CloseRoundedIcon
                className="text-lg absolute right-4 top-1 h-full"
                onClick={handleCloseSearch}
              />
            </Box>
            <Box
              component="div"
              className="p-5 overflow-y-scroll"
              sx={{ height: 'calc(100% - 2rem)' }}
            >
              <LinkList />
            </Box>
          </>
        ) : (
          <Box
            component="div"
            className="bg-white text-primary p-10 overflow-y-scroll hide-scrollbar max-h-[600px]"
          >
            <Box
              component="div"
              className="text-xl font-semibold flex items-center justify-between"
            >
              <Box>Try these popular nearby searches</Box>
              <ClearRoundedIcon className="text-xl cursor-pointer" onClick={handleCloseSearch} />
            </Box>
            <LinkList />
          </Box>
        )}
      </Box>
    </>
  )
}
export default NearSearches
