// import * as Sentry from '@sentry/nextjs'
import { IntlProvider } from 'react-intl'
import { parse, serialize } from 'cookie'
import Script from 'next/script'
import { useEffect } from 'react'
import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ApolloProvider, useReactiveVar } from '@apollo/client'
import { StatsigProvider, StatsigUser } from 'statsig-react'
import pick from 'lodash/pick'
import { isMobile } from 'react-device-detect'
import Router, { useRouter } from 'next/router'
import { getAppSource, setAppSource } from '@/lib/storage'
import { DIALOG_ID_KEY } from '@/constants/chat'
import Head from 'next/head'
import NProgress from 'nprogress' // nprogress module
import 'nprogress/nprogress.css' // styles of nprogress
import Layout from '../components/layout'
import '../styles/global.scss'
import { useApollo } from '../lib/apolloClient'
import { themeConfig } from '../lib/themeConfig'
import { userInfoCatch } from '../lib/catch'
import { userId as catchUserId } from '../state/userId'
import { averta, holidayFreeFont, ramarajaFont, minion } from '../asset/font'
import en from '../lang/en.json'
import zh from '../lang/zh.json'
import es from '../lang/es.json'

const messages = {
  en,
  zh,
  es
}

let previousUrl = ''
const referrerUrl = typeof window !== 'undefined' ? window.document.referrer : ''

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', nextPath => {
  NProgress.start()
  previousUrl = nextPath
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// the Breakpoints same with the tailwind config
const theme = createTheme(themeConfig)
let userAgentData = {}
let source: string | string[] = isMobile ? 'mweb' : 'web'

if (typeof window !== 'undefined') {
  const cookie = parse(document.cookie)
  const vid = cookie.statsig_id || ''
  if (vid) {
    localStorage.setItem('VID', vid)
  }

  // 本地的dialogId带给服务端
  const dialogId = localStorage.getItem(DIALOG_ID_KEY) || ''
  if (dialogId) {
    document.cookie = serialize('dialog_id', dialogId, { path: '/' })
  }

  userAgentData = pick(window.navigator, [
    'vendorSub',
    'productSub',
    'vendor',
    'maxTouchPoints',
    'doNotTrack',
    'pdfViewerEnabled',
    'hardwareConcurrency',
    'cookieEnabled',
    'appCodeName',
    'appName',
    'appVersion',
    'platform',
    'product',
    'userAgent',
    'language',
    'onLine',
    'webdriver',
    'deviceMemory'
  ])

  // 手动设置css的屏幕高度变量
  const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-vh', `${window.innerHeight / 100}px`)
    doc.style.setProperty('--doc-h', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', documentHeight)
  documentHeight()
}

const App = ({ Component, pageProps }) => {
  const client = useApollo(pageProps)
  const { ip = '', uid = '', isMobileDevice } = pageProps
  const { query, asPath, locale } = useRouter()
  const { userId } = useReactiveVar(userInfoCatch)

  if (typeof window !== 'undefined') {
    if (query.source) {
      source = query.source
    } else {
      source = getAppSource() ? JSON.parse(getAppSource()) : source
    }
  }

  catchUserId(uid)

  const statsigUser = {
    // sessionID: VisitorId,
    userID: uid,
    userLoginId: userId || undefined,
    statsigID: uid,
    userAgent: { ...userAgentData, ip },
    userUrl: decodeURIComponent(asPath),
    userPreviousUrl: previousUrl || referrerUrl,
    userUrlParams: {
      ...query,
      source
    }
  }
  const initAppSessionAction = () => {
    /**
     * url source
     * if has source in url: update session storage and use the url source
     * if has no source in url: use session storage or default source
     */
    if (query.source) {
      setAppSource(query.source ? JSON.stringify(query.source) : JSON.stringify(source))
    }
  }

  /**
   * every mount or update to check
   */
  useEffect(initAppSessionAction)

  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
        `}
      </Script>
      {query?.vConsole === '1' && isMobile ? (
        <Script
          src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
          onReady={() => {
            // eslint-disable-next-line no-new, @typescript-eslint/no-explicit-any
            new (window as any).VConsole()
          }}
        />
      ) : null}
      <style jsx global>
        {`
          :root {
            --font-holidayFree: ${holidayFreeFont.style.fontFamily};
            --font-averta: ${averta.style.fontFamily};
            --font-ramaraja: ${ramarajaFont.style.fontFamily};
            --font-minion: ${minion.style.fontFamily};
            --doc-h: 100%;
          }
        `}
      </style>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <IntlProvider locale={locale} messages={messages[locale]}>
              {/* Your component tree. Now you can override MUI's styles. */}
              {process.env.NEXT_PUBLIC_STATSIG_API_KEY ? (
                <StatsigProvider
                  sdkKey={process.env.NEXT_PUBLIC_STATSIG_API_KEY}
                  user={statsigUser as unknown as StatsigUser}
                  options={{
                    disableErrorLogging: true,
                    environment: {
                      tier: process.env.STAGING
                    }
                  }}
                  // 确保stating 初始化完成后再渲染子组件
                  waitForInitialization={false}
                >
                  <Layout isMobile={isMobileDevice}>
                    <Component {...pageProps} userID={statsigUser.userID} />
                  </Layout>
                </StatsigProvider>
              ) : (
                <Layout isMobile={isMobileDevice}>
                  <Component {...pageProps} userID={statsigUser.userID} />
                </Layout>
              )}
            </IntlProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </>
  )
}
export default App
