import { useEffect, useState } from 'react'

export const getIsMobile = req => {
  const deviceAgent = req.headers['user-agent']
  return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(
    deviceAgent
  )
}

export const isMobile = () =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(
    window.navigator.userAgent
  )

export const useInClient = () => {
  const [inClient, setInClient] = useState(typeof window !== 'undefined')
  useEffect(() => {
    setInClient(true)
  }, [])
  return inClient
}
