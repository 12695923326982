import { parse, serialize } from 'cookie'

export const DIALOG_ID_KEY = 'RENTY_DIALOG_ID_KEY'

export const setDialogId = (val: string) => {
  localStorage.setItem(DIALOG_ID_KEY, val || '')

  if (val) {
    // 本地的dialogId带给服务端
    document.cookie = serialize('dialog_id', val, { path: '/' })
  } else {
    // 本地的dialogId带给服务端
    document.cookie = serialize('dialog_id', '', { path: '/' })
  }
}

export const getDialogId = () => {
  const cookie = parse(document.cookie)
  const dialogIdByCookie = cookie.dialog_id
  const localId = localStorage.getItem(DIALOG_ID_KEY)
  return localId || dialogIdByCookie || null
}
