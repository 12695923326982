import { Box, Link, Typography } from '@mui/material'
import upperFirst from 'lodash/upperFirst'
import { cityRouteMap, locations } from '@/asset/searchData'
import { generatePath, generateZipcodePath } from '@/lib/urlParse'
import { getCityAreaValue, getCityAreaValueFromPath, getCaOrZipData } from '../../lib/cityArea'
import { findZipCodesForCA } from '../../lib/zipCode'
import { statsigLog } from '../../lib/statsigAndGa'
import { amenitiesMap, bedsMap, maxPrices, specialMap } from './filerMap'
import { regionDataList, getLocalGuidePath } from '../localGuide/cityData'

const getAreaName = (cityPath, areaPath) => cityRouteMap[areaPath || cityPath]
const getNearOrIn = areaRoute => (areaRoute === 'usc' ? 'near' : 'in')

const amenitiesList = (city: string, area = '') => {
  const cityName = cityRouteMap[city]
  const areaName = cityRouteMap[area]
  const linkNameSuffix = `${getNearOrIn(area)} ${getAreaName(city, area)}`
  const prePath = generatePath(cityName, areaName)
  const keys = Object.keys(amenitiesMap)
  const { city: cityMap, area: areaMap } = getCityAreaValueFromPath(city, area)
  const isUsc = ['USC'].includes(areaMap.name)
  const { state } = locations.find(v => v.name === cityMap.name)
  const place = `${areaMap?.name || cityMap.name}${state ? `, ${state}` : ''}`
  const nearArea = isUsc ? `near` : `in`
  const locationTitle = isUsc ? `near ${areaMap?.name}` : `in ${place}`
  const houseType = isUsc ? 'Student Housing' : 'Apartments'
  return [
    {
      name: 'Pet friendly',
      href: `${prePath}/${keys[0]}`,
      title: `Cheap Pet-Friendly ${houseType} for Rent ${locationTitle} | Renty.AI`,
      desc: `Find pet-friendly${
        isUsc ? ' off-campus' : ''
      } apartments for rent ${locationTitle}. View photos and request tours. Use our filters to find a cheap pet-friendly ${houseType.toLocaleLowerCase()} you love.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Pet friendly {` `}
          </Box>
          apartments {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Parking',
      href: `${prePath}/${keys[1]}`,
      title: `Affordable ${houseType} with Parking for Rent ${locationTitle} | Renty.AI`,
      desc: `Find${
        isUsc ? ' off-campus' : ''
      } apartments with parking for rent ${nearArea} ${place}. View photos and request tours. Use our filters to find a cheap ${houseType.toLocaleLowerCase()} with parking you love.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Apartments with Parking{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Private Bathroom',
      href: `${prePath}/${keys[2]}`,
      title: `${
        isUsc ? 'Top Off-Campus ' : ''
      }Apartments with Private Bathroom for Rent ${locationTitle} | Renty.AI`,
      desc: `Find${
        isUsc ? ' off-campus' : ''
      } apartments with private bathrooms for rent ${nearArea} ${place}. View photos and request tours. Use our city and area filters to find an affordable ${houseType.toLocaleLowerCase()}.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Apartments with Private Bathroom{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Furnished',
      href: `${prePath}/${keys[3]}`,
      title: `Fully-Furnished${
        isUsc ? ' Off-Campus' : ''
      } Apartments for Rent ${locationTitle} | Renty.AI`,
      desc: `Find fully furnished${isUsc ? ' off-campus' : ''} apartments for rent ${nearArea} ${
        areaMap?.name || cityMap.name
      }. View photos and request tours. Use our city and area filters to find an affordable furnished ${houseType.toLocaleLowerCase()}.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Furnished Apartments{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Pool',
      href: `${prePath}/pool`,
      title: `Luxurious${
        isUsc ? ' Off-Campus' : ''
      } Apartments With Pools ${locationTitle} | Modern Living`,
      desc: `Find${isUsc ? ' luxurious off-campus' : ''} apartments with pools ${
        isUsc ? `near ${areaMap?.name}` : `in the heart of ${areaMap?.name || cityMap.name}`
      } . Featuring world-class facilities. Find your dream home, browse photos, and get special offers.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Apartments with pools{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Washer/Dryer',
      href: `${prePath}/in-unit-wd`,
      title: `Convenient${
        isUsc ? ' Off-Campus' : ''
      } Apartments ${locationTitle} with In-Unit Washer/Dryer`,
      desc: `Explore${isUsc ? ' off-campus' : ''} apartments ${nearArea} ${
        areaMap?.name || cityMap.name
      }featuring convenient in-unit washers/dryers. View listings, check availability, and secure your ideal apartment now!`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Apartments with washer/dryer in unit{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    }
  ]
}

const bedroomsList = (city: string, area: string) => {
  const cityName = cityRouteMap[city]
  const areaName = cityRouteMap[area]
  const linkNameSuffix = `apartment rental ${getNearOrIn(area)} ${getAreaName(city, area)}`
  const prePath = generatePath(cityName, areaName)
  const keys = Object.keys(bedsMap)
  const { city: cityMap, area: areaMap } = getCityAreaValueFromPath(city, area)
  const isUsc = ['USC'].includes(areaMap.name)
  const nearArea = isUsc ? 'near' : 'in'
  const houseType = isUsc ? 'Student Housing' : 'Apartments'
  const preTitle = isUsc ? 'Cheap' : 'Affordable'
  const { state } = locations.find(v => v.name === cityMap.name)
  return keys.map(k => ({
    name: upperFirst(k),
    href: `${prePath}/${k}`,
    title: `${preTitle} ${upperFirst(k.replace('-', ' '))} ${houseType} for Rent ${nearArea} ${
      areaMap?.name || cityMap.name
    }${state ? `, ${state}` : ''} | Renty.AI`,
    desc: `Find top${k.replace('-', ' ')} ${
      isUsc ? ' off-campus' : ''
    } apartments for rent ${nearArea} ${
      areaMap?.name || cityMap.name
    }. View photos and request tours. Use our city and area filters to find an affordable studio you love.`,
    txt: (
      <>
        <Box component="span" className="font-semibold">
          {upperFirst(k)}{' '}
        </Box>
        {linkNameSuffix}
      </>
    )
  }))
}

const priceList = (city: string, area: string) => {
  const cityName = cityRouteMap[city]
  const areaName = cityRouteMap[area]
  const linkNameSuffix = `${getNearOrIn(area)} ${getAreaName(city, area)}`
  const prePath = generatePath(cityName, areaName)
  const keys = Object.keys(maxPrices)
  const { city: cityMap, area: areaMap } = getCityAreaValueFromPath(city, area)
  const isUsc = ['USC'].includes(areaMap.name)
  const nearArea = isUsc ? 'near' : 'in'
  const houseType = isUsc ? 'Student Housing' : 'Apartments'
  const { state } = locations.find(v => v.name === cityMap.name)
  const place = isUsc ? areaMap?.name : areaMap?.name || cityMap.name
  return keys.map(item => ({
    name: `Under $${item}`,
    href: `${prePath}/max-price-${item}`,
    title: `Best ${houseType} for Rent Under $${item} ${nearArea} ${areaMap?.name || cityMap.name}${
      state ? `, ${state}` : ''
    } | Renty.AI`,
    desc: `Find${
      isUsc ? ' off-campus' : ''
    } apartments for rent under $${item} ${nearArea} ${place}. View photos and request tours. Use our city and area filters to find an affordable ${houseType.toLocaleLowerCase()} you love.`,
    txt: (
      <>
        <Box component="span" className="font-semibold">
          Apartment rentals under ${item}{' '}
        </Box>
        {linkNameSuffix}
      </>
    )
  }))
}

const zipCodesInCity = (
  city: { name?: string; path?: string },
  area: { name?: string; path?: string }
) => {
  const { state } = locations.find(v => v.name === city.name)
  const isUsc = ['USC'].includes(area.name)
  const houseType = isUsc ? 'Cheap Student Housing' : 'Apartment'
  const campus = isUsc ? 'cheap off-campus' : 'top'
  return findZipCodesForCA(city.name, area.name).map(zip => ({
    name: `zip code ${zip}`,
    href: generateZipcodePath(zip),
    title: `${houseType} for Rent in ${zip} | ${area.name} ${city.name}${
      state ? `, ${state}` : ''
    } | Renty.AI`,
    desc: `Find ${campus} apartments for rent in zip ${zip} ${area.name} ${city.name}. View photos and request tours. Use our city and area filters to find an affordable apartment you love.`,
    txt: (
      <>
        <Box component="span" className="font-semibold">
          {`${zip}`}{' '}
        </Box>
        apartments for rent
      </>
    )
  }))
}

const specialList = (city: string, area: string) => {
  const cityName = cityRouteMap[city]
  const areaName = cityRouteMap[area]
  const linkNameSuffix = `${getNearOrIn(area)} ${getAreaName(city, area)}`
  const prePath = generatePath(cityName, areaName)
  const keys = Object.keys(specialMap)
  const { city: cityMap, area: areaMap } = getCityAreaValueFromPath(city, area)
  const isUsc = ['USC'].includes(areaMap.name)
  const houseType = isUsc ? 'Student Housing' : 'Apartments'
  const { state } = isUsc ? { state: '' } : locations.find(v => v.name === cityMap.name)
  const titleLocation = isUsc
    ? 'near USC'
    : `in ${areaMap?.name || cityMap.name}${state ? `, ${state}` : ''}`
  return [
    {
      name: 'Immediate Move-In',
      href: `${prePath}/${keys[0]}`,
      title: `${
        isUsc ? 'Cheap' : ''
      } Immediate Move-In ${houseType} for Rent ${titleLocation} | Renty.AI`,
      desc: `Find cheap${
        isUsc ? ' off-campus' : ''
      } apartments for rent for immediate move-in ${titleLocation}. View photos and request tours. Use our city and area filters to find ${
        isUsc ? 'a student housing' : 'an apartment'
      } you love.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Immediate Move-In rentals{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Cheapest apartments',
      href: `${prePath}/${keys[1]}`,
      title: `Cheap ${houseType} for Rent ${titleLocation} | Renty.AI`,
      desc: `Find cheap${
        isUsc ? ' off-campus' : ''
      } apartments for rent ${titleLocation}. View photos and request tours. Use our city and area filters to find a cheap ${
        isUsc ? 'student housing' : 'apartment'
      } you love.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Cheapest rental homes{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Homes for rent',
      href: `${prePath}`,
      title: `${
        isUsc ? `Off-Campus Cheap` : 'Cheap & Best'
      } Apartments for Rent ${titleLocation} | Renty.AI`,
      desc: `Discover ${
        isUsc ? 'affordable student housing' : 'great, affordable apartments'
      } for rent ${titleLocation}! Explore a diverse selection with unbeatable rental prices using apartment locators on Renty.AI.`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Rental homes{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Luxury Apartments',
      href: `${prePath}/luxury`,
      title: `Top Luxury Apartments for rent ${titleLocation} | Renty.AI`,
      desc: `Discover Luxury Apartments rent ${titleLocation} - Modern design, top-tier amenities, and prime locations for your convenience. Discover a new standard of high-end living today!`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Luxury rental homes{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    },
    {
      name: 'Small apartments',
      href: `${prePath}/small-apartments`,
      title: `Affordable small ${houseType} for rent ${titleLocation} | Cozy & Convenient`,
      desc: `Find small, affordable${
        isUsc ? ' off-campus' : ''
      } apartments ${titleLocation} with convenient amenities. Explore our listings and find your ideal living space today!`,
      txt: (
        <>
          <Box component="span" className="font-semibold">
            Small rental homes{' '}
          </Box>
          {linkNameSuffix}
        </>
      )
    }
  ]
}

export const getLinkList = (cityName: string, areaName?: string) => {
  const { city: cityMap, area: areaMap } = getCityAreaValue(cityName, areaName)
  const amenites = amenitiesList(cityMap.path, areaMap.path)
  const beds = bedroomsList(cityMap.path, areaMap.path)
  const price = priceList(cityMap.path, areaMap.path)
  const zipCode = zipCodesInCity(cityMap, areaMap)
  const speicals = specialList(cityMap.path, areaMap.path)

  const lastList = [
    {
      name: 'Amenities',
      list: amenites
    },
    {
      name: 'Bedrooms',
      list: beds
    },
    {
      name: 'Price',
      list: price
    },
    {
      name: 'Zipcode',
      list: zipCode
    },
    {
      name: 'Find specialty housing',
      list: speicals
    }
  ]
  return lastList
}

export const getCityPathList = cities => {
  const userCitiesRoute = cities
    ?.map(city => getCityAreaValue(city))
    ?.map(({ city }) => city.path)
    .filter(city => !!city)
  const cityRoute =
    cities && userCitiesRoute?.length
      ? userCitiesRoute
      : [
          'los-angeles',
          'west-hollywood',
          'glendale',
          'irving',
          'houston',
          'killeen',
          'corpus-christi',
          'seattle'
        ]
  const cityList = locations.filter(({ route: areaRoute }) => cityRoute.includes(areaRoute))
  const lastList = []

  // 排除不许要展示的路由
  const noDataMap = {
    'Pet friendly': ['irving', 'houston', 'killeen', 'corpus-christi'],
    Parking: ['irving', 'houston', 'killeen', 'corpus-christi'],
    'Private Bathroom': [],
    Furnished: ['irving', 'houston', 'killeen', 'corpus-christi'],
    studio: ['irving', 'killeen', 'corpus-christi'],
    '1-bedroom': [],
    '2-bedroom': [],
    '3-bedroom': ['irving', 'houston', 'killeen'],
    'Immediate Move-In': [],
    'Under $2000': ['west-la-ucla-student-housing']
  }
  cityList.forEach(item => {
    const { area: areaList, name: cityName, id, parent_id: parentId, route } = item
    lastList.push({
      id,
      parent_id: parentId,
      route,
      name: cityName,
      linkList: getLinkList(cityName)
    })

    areaList?.forEach(area => {
      const { name: areaName } = area || {}
      lastList.push({ ...area, linkList: getLinkList(cityName, areaName) })
    })
  })

  const newLastList = lastList.map(v => {
    const { value: cityArea } = getCaOrZipData(v.route)
    v.linkList = v.linkList.map(item => {
      item.list = item.list.filter(route => {
        const noDataRoute = noDataMap[route.name]
        if (noDataRoute) {
          return !noDataRoute.includes(v.route)
        }

        // 排除 LA 城市下的低于1000的链接
        if (item.name === 'Price' && ['Los Angeles', 'Seattle'].includes(cityArea.city)) {
          const curPrice = ~~route.name.replace('Under $', '')
          return curPrice > 1000
        }

        // 只有downtown 的房子才有 luxury
        if (route.name === 'Luxury Apartments') {
          return (
            ['DTLA'].includes(cityArea.area) ||
            (['Los Angeles'].includes(cityArea.city) && !cityArea.area)
          )
        }

        return true
      })
      return item
    })
    return v
  })
  return newLastList
}

const cityAreaPopularMap = {
  'Los Angeles': [
    {
      name: 'Luna apartments',
      id: 10020010,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/luna/10020010'
    },
    {
      name: 'Sawyer apartments',
      id: 10020006,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/sawyer/10020006'
    },
    {
      name: 'The Thompson',
      id: 10020008,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-thompson/10020008'
    },
    {
      name: 'The Arden',
      id: 10020011,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-arden/10020011'
    },
    {
      name: 'The Harper',
      id: 10000106,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-harper/10000106'
    }
  ],
  Koreatown: [
    {
      name: 'The Kenmore',
      id: 10000108,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-kenmore/10000108'
    },
    {
      name: 'The Audrey',
      id: 10000105,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-audrey/10000105'
    },
    {
      name: 'Atlas House',
      id: 10019976,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/atlas-house/10019976'
    },
    {
      name: 'The Roya apartments',
      id: 50000027,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-roya/50000027'
    },
    {
      name: '30Sixty',
      id: 10020007,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/30-sixty/10020007'
    },
    {
      name: 'Hana apartments',
      id: 10000107,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-hana/10000107'
    },
    {
      name: 'Crosby on Wilshire',
      id: 10020009,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/crosby-on-wilshire/10020009'
    },
    {
      name: 'The Nexen apartments',
      id: 10000272,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-nexen/10000272'
    },
    {
      name: 'Nova apartments',
      id: 10020012,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/nova-apartments-on-wilshire/10020012'
    },
    {
      name: 'LA Sienna apartments',
      id: 10019986,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/the-sienna/10019986'
    }
  ],
  Hollywood: [
    {
      name: 'The Archer apartments',
      id: 10000104,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/archer/10000104'
    },
    {
      name: 'La Vida Melrose',
      id: 10020025,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/la-vida-melrose/10020025'
    },
    {
      name: 'Gemma apartments',
      id: 10000083,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/gemma-north/10000083'
    }
  ],
  'West LA': [
    {
      name: 'Maxwell apartments',
      id: 10019964,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/maxwell/10019964'
    },
    {
      name: '3737 Motor Ave',
      id: 10000137,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/3737-motor-ave/10000137'
    }
  ],
  Houston: [
    {
      name: 'Tierra Del Sol',
      id: 10020027,
      href: 'https://renty.ai/apartment-buildings/houston-tx/tierra-del-sol/10020027'
    },
    {
      name: 'Arcadia at Westheimer',
      id: 10020023,
      href: 'https://renty.ai/apartment-buildings/houston-tx/arcadia-at-westheimer/10020023'
    },
    {
      name: 'South Main Building',
      id: 10020014,
      href: 'https://renty.ai/apartment-buildings/houston-tx/south-main-building/10020014'
    },
    {
      name: 'The Retreat at Cypress Station',
      id: 10020017,
      href: 'https://renty.ai/apartment-buildings/houston-tx/the-retreat-at-cypress-station/10020017'
    },
    {
      name: 'West Oaks Landing',
      id: 10020020,
      href: 'https://renty.ai/apartment-buildings/houston-tx/west-oaks-landing/10020020'
    },
    {
      name: 'Woodtrail Apartment Homes',
      id: 10020030,
      href: 'https://renty.ai/apartment-buildings/houston-tx/woodtrail-apartment-homes/10020030'
    },
    {
      name: 'Estates at Fountain Lake',
      id: 10020029,
      href: 'https://renty.ai/apartment-buildings/houston-tx/estates-at-fountain-lake/10020029'
    },
    {
      name: 'Mansions Of Shadowbriar',
      id: 10020016,
      href: 'https://renty.ai/apartment-buildings/houston-tx/mansions-of-shadowbriar/10020016'
    }
  ],
  'Corpus Christi': [
    {
      name: 'Crosswinds Apartments',
      id: 10020031,
      href: 'https://renty.ai/apartment-buildings/corpus-christi-tx/crosswinds-apartments/10020031'
    },
    {
      name: `Chandler's Mill`,
      id: 10020026,
      href: 'https://renty.ai/apartment-buildings/corpus-christi-tx/chandlers-mill/10020026'
    }
  ],
  DTLA: [
    {
      name: 'TENTEN WILSHIRE',
      id: 10020106,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/tenten-wilshire/10020106'
    },
    {
      name: `TENTEN DOWNTOWN`,
      id: 10020107,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/tenten-downtown/10020107'
    },
    {
      name: `1133 S Hope St`,
      id: 10000001,
      href: 'https://renty.ai/apartment-buildings/los-angeles-ca/1133-s-hope-st/10000001'
    }
  ],
  Killeen: [
    {
      name: 'The Remington',
      id: 10020032,
      href: 'https://renty.ai/apartment-buildings/killeen-tx/the-remington/10020032'
    }
  ],
  Seattle: [
    {
      name: 'Stream Dexios',
      id: 10020109,
      href: 'https://renty.ai/apartment-buildings/seattle-wa/stream-dexios/10020109'
    }
  ],
  Irving: [
    {
      name: 'The Cayman Las Colinas',
      id: 10020033,
      href: 'https://renty.ai/apartment-buildings/irving-tx/the-cayman-las-colinas/10020033'
    }
  ]
}

const LinkList = ({ showAll = true, cities = null }) => {
  const lastList = getCityPathList(cities)

  const handleClick = item => {
    statsigLog('specific_URL_name_click', {
      special_name: item.name
    })
  }

  const handlePropertyClick = item => {
    statsigLog('property_name_click', {
      property_name: item.name
    })
  }

  const showGuidePageCityArea = regionDataList.map(v => v.name)

  return lastList.length > 0 ? (
    <>
      {(showAll ? lastList : [lastList[0]]).map(({ name, linkList }) => (
        <Box key={name}>
          <Typography className="mt-6 font-averta text-[20px] font-semibold">{name}</Typography>
          {showGuidePageCityArea.includes(name) ? (
            <Link
              href={getLocalGuidePath(name)}
              className="text-primary inline-block -mb-4 py-4 text-base font-semibold underline"
            >
              Learn More about {name}
            </Link>
          ) : null}
          <Box
            component="div"
            className="mt-4 mb-2 md:mt-4 md:mb-4 text-xs md:text-base font-semibold"
          >
            Homes for rent {name === 'USC' ? 'near' : 'in'} {name}
          </Box>
          <Box
            className={`h-fit rounded-[20px] md:border border-gray-200 px-3 py-4 md:p-5 flex flex-wrap gap-4 md:gap-10 bg-[#F7F7F7] md:bg-[transparent] ${
              !showAll ? 'max-h-[324px] overflow-hidden' : ''
            }`}
          >
            {linkList
              .filter(item => item.name !== 'Zipcode')
              .map(item => (
                <Box
                  key={item.name}
                  component="div"
                  className="w-full md:w-[calc((100%_-_2.5rem)_/_2)] lg:w-[calc((100%_-_5rem)_/_3)]"
                >
                  <Box className="text-xs md:text-base font-semibold mb-2">{item.name}</Box>
                  {item.list.map(v => (
                    <Link
                      key={v.name}
                      href={v.href}
                      onClick={() => handleClick(v)}
                      className="block py-2 text-ellipsis overflow-hidden w-full whitespace-nowrap text-xs md:text-base underline"
                      target="_self"
                    >
                      {v.txt}
                    </Link>
                  ))}
                </Box>
              ))}
            {cityAreaPopularMap[name] && cityAreaPopularMap[name].length ? (
              <Box
                component="div"
                className="w-full md:w-[calc((100%_-_2.5rem)_/_2)] lg:w-[calc((100%_-_5rem)_/_3)]"
              >
                <Box className="text-xs md:text-base font-semibold mb-2">
                  Popular Apartment Communities
                </Box>
                {cityAreaPopularMap[name].map(v => (
                  <Link
                    key={v.name}
                    href={v.href}
                    onClick={() => handlePropertyClick(v)}
                    className="block py-2 text-ellipsis overflow-hidden w-full whitespace-nowrap text-xs md:text-base underline"
                    target="_self"
                  >
                    <Box component="span" className="font-semibold">
                      {v.name}
                    </Box>
                  </Link>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      ))}
    </>
  ) : null
}

export default LinkList
