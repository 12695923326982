import { Statsig, useStatsigLogEffect } from 'statsig-react'
import isBoolean from 'lodash/isBoolean'
import { chatSessionId } from '../state/chatId'
import { userId as catchUserId } from '../state/userId'
import { isMapMode } from '../state/config'
import { isMobile } from './getDevice'
import { userInfoCatch } from './catch'

/**
 *
 * @param name: string
 * @param data: {}
 */
export const statsigLog = (name, data = {}) => {
  if (name && process.env.NEXT_PUBLIC_STATSIG_API_KEY) {
    if (!Statsig.initializeCalled()) {
      return
    }
    const { userId } = userInfoCatch()
    const mobile = isMobile()
    const otherData = {}
    if (!mobile) {
      otherData.mode = isMapMode() ? 'map' : 'chat'
    }
    Statsig.logEvent(name, null, {
      session_id: chatSessionId(),
      userLoginId: userId,
      terminal: mobile ? 'mobile' : 'PC',
      ...otherData,
      ...data
    })
  }
}

export const useStatsig = (name, data, isMobiles) => {
  if (name && process.env.NEXT_PUBLIC_STATSIG_API_KEY) {
    const { userId } = userInfoCatch()
    const mobile = isBoolean(isMobiles) ? isMobiles : typeof window !== 'undefined' && isMobile()
    const otherData = {}
    if (!mobile) {
      otherData.mode = isMapMode() ? 'map' : 'chat'
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStatsigLogEffect(name, null, {
      session_id: chatSessionId(),
      userLoginId: userId,
      statsigID: catchUserId(),
      terminal: mobile ? 'mobile' : 'PC',
      ...otherData,
      ...data
    })
  }
}

/**
 *
 * @param name: string
 * @param data: {}
 */

export const gaLog = (name, data = {}) => {
  if (window && name && process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    window.gtag('event', name, {
      session_id: chatSessionId(),
      statsigID: catchUserId(),
      ...data
    })
  }
}
