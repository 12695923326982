import toLower from 'lodash/toLower'
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
import { locations } from '@/asset/searchData'

const data = locations

export const findZipCodesForCA = (city, area) => {
  const matchCity = data.find(({ name }) => toLower(name) === toLower(city))
  if (!area && city) {
    if (matchCity) {
      return orderBy(
        uniq([
          ...matchCity.zipCode,
          ...(matchCity.area || []).reduce((acc, item) => {
            acc.push(...item.zipCode)
            return acc
          }, [])
        ])
      )
    }
  }
  const matchArea = matchCity && matchCity.area.find(({ name }) => toLower(name) === toLower(area))
  return (matchArea && matchArea.zipCode) || []
}

export const findCaFromZipCode = code => {
  const zip = Number(code)
  const ca = {
    city: '',
    area: '',
    cityLowcaseName: '',
    areaLowcaseName: ''
  }
  const matchCity = data.find(({ zipCode, area, name, route }) => {
    if (zipCode.includes(zip)) {
      ca.city = name
      ca.area = ''
      ca.cityLowcaseName = route
      ca.areaLowcaseName = ''
      return true
    }
    return area.find(({ zipCode: areaZipCode, name: areaName, route: areaRoute }) => {
      if (areaZipCode.includes(zip)) {
        ca.city = name
        ca.area = areaName
        ca.cityLowcaseName = route
        ca.areaLowcaseName = areaRoute
        return true
      }
      return false
    })
  })
  if (matchCity) {
    return ca
  }
  return {
    city: 'Los Angeles',
    area: '', // USC
    cityLowcaseName: 'los-angeles',
    areaLowcaseName: '' // usc
  }
}
