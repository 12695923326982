import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useMapMode } from '@/state/config'
import { USER_INFORMATION } from '../graphqls/queries'
import { userInfoCatch } from '../lib/catch'
import { deleteCookie } from '../lib/user'
import NearSearches from './nearSearches'

const GoogleTapLogin = dynamic(() => import('./googleTapLogin'), {
  ssr: false
})

const MobileLoginDrawer = dynamic(() => import('@/components/login/mobileLoginDrawer'), {
  ssr: false
})

const Layout = ({ children, isMobile = false }) => {
  const [isLogin, setIsLogin] = useState(true)
  useMapMode()

  const [queryUser] = useLazyQuery(USER_INFORMATION, {
    onCompleted: res => {
      const { userInformation: userInformationData } = res || {}
      const { isExpired = true, userInfo } = userInformationData || {}
      const { email, firstName, userId, lastName } = userInfo || {}
      if (isExpired) {
        setIsLogin(false)
        deleteCookie()
        userInfoCatch({
          email: '',
          name: '',
          userId: '',
          firstName: '',
          lastName: ''
        })
      } else {
        setIsLogin(true)
        userInfoCatch({
          email,
          name: firstName || 'anonymous',
          userId,
          firstName,
          lastName
        })
      }
    }
  })

  useEffect(() => {
    queryUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {children}
      {!isLogin ? <GoogleTapLogin /> : null}
      <NearSearches isMobileDevice={isMobile} />
      {isMobile && <MobileLoginDrawer />}
    </>
  )
}

export default Layout
