// eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
const requestIP = require('request-ip')

const getIp = req => {
  if (!req) {
    console.error('req not exist')
    return null
  }

  return requestIP.getClientIp(req) || req.socket.remoteAddress
}

export { getIp }
