// 全局的一些配置信息
import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { useInClient } from '@/lib/getDevice'

export const isMapMode = makeVar<boolean>(true)

export const offsetTop = makeVar(60)

export const MapModeKEY = 'RENTY_AI_MAP_MODE'

export const useMapMode = () => {
  const mapMode = useReactiveVar(isMapMode)
  const isClient = useInClient()
  useEffect(() => {
    if (!isClient) return
    window.localStorage.setItem(MapModeKEY, mapMode ? 'true' : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode])
}
