export const amenitiesMap = {
  'pet-friendly': {
    amenities: ['Pet Friendly']
  },
  'with-parking': {
    amenities: ['Parking']
  },
  'with-private-bathroom': {
    amenities: ['Private Bathroom']
  },
  furnished: {
    amenities: ['Furnished']
  }
} as const

export const bedsMap = {
  studio: {
    bedsFilterBy: [0]
  },
  '1-bedroom': {
    bedsFilterBy: [1, 2, 3, 4, 5, 6]
  },
  '2-bedroom': {
    bedsFilterBy: [2, 3, 4, 5, 6]
  },
  '3-bedroom': {
    bedsFilterBy: [3, 4, 5, 6]
  }
}

export const maxPrices = [700, 800, 1000, 2000, 2500, 3000, 3500, 4500]
  .map(k => ({ [k]: { minPrice: 1, maxPrice: k } }))
  .reduce((p, c) => ({ ...p, ...c }), {})

export const specialMap = {
  'available-now': {
    moveInStartTimeEqualTo: () => new Date().valueOf(),
    moveInEndTimeEqualTo: () => new Date().valueOf()
  },
  'cheap-apartments': {
    sortBy: {
      field: 'minPrice',
      order: 'ASC'
    }
  }
}

export const generateFilterMap = () => {
  const filterMap = {
    ...amenitiesMap,
    ...bedsMap,
    apartments: {},
    'cheap-apartments': {},
    'available-now': {
      moveInStartTimeEqualTo: new Date().valueOf(),
      moveInEndTimeEqualTo: new Date().valueOf()
    }
  }
  const priceFilter = Object.keys(maxPrices).reduce(
    (p, k) => ({ ...p, [`max-price-${k}`]: maxPrices[k] }),
    {} as { [key: `max-price-${number}`]: (typeof maxPrices)[number] }
  )
  return { ...filterMap, ...priceFilter }
}
